@import '../../../../styles/variables';

.highlighted {
  outline: 2px solid $AuxiliarColor;
}

.closeIcon {
  color: $AuxiliarColor;
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 6px;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: $SecondaryColor;
  }
}

.tooltipContainer {
  background-color: white;
  border-radius: 6px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  font-size: 12px;
  padding: 8px;
  position: absolute;

  h4 {
    font-size: 16px;
    margin-bottom: 0;
    padding: 6px 0 2px 8px;
  }

  .success {
    color: $SuccessColor;
  }
  .error {
    color: $ErrorColor;
  }

  .tooltipContent {
    background: white;
    color: black;
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    min-width: 300px;
    padding: 8px;

    p, div {
      display: grid;
      font-size: 14px;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 4px !important;
      padding: 0;
    }
  }

  .auditorsContainer {
    .auditors {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  .auditorExtraInfo {
    border: 1px solid rgba($SecondaryColor, 0.4);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    padding: 0px 20px;
    .checks {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      list-style-type: none;
      margin: 12px 0 4px 0;
      padding: 0;

      li {
        font-size: 14px;
        text-align: center;
      }
    }
    .ctaContainer {
      display: flex;
      justify-content: center;
      padding: 8px 0;
      .whatsAppBtn {
        background-color: transparent;
        border: 1px solid $SecondaryColor;
        border-radius: "50%";
        display: block;
        margin-bottom: 6px;
        max-width: 200px;
        padding: 8px 16px;
        a {
          color: $SecondaryColor;
          text-decoration: none;
        }
      }
    }
  }
}
